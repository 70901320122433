<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12">
                <AMonthPicker
                    v-model:value="state.params.bulan"
                    :allow-clear="false"
                    placeholder="Pilih Bulan"
                    style="width:100%;"
                    format="MMMM YYYY"/>
            </ACol>
            <ACol
                v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TIM_CRM])"
                :xl="6" :md="8" :sm="12">
                <FilterATSalesmanUserAMPeriode
                    style="width:100%;"
                    v-model:value="state.params.useram_id"
                    v-model:periode="state.params.bulan"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterATSalesmanDistributorPeriode
                    style="width:100%;"
                    v-model:value="state.params.distributor"
                    v-model:periode="state.params.bulan"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterSalesman
                    style="width:100%;"
                    v-model:value="state.params.salesman_id"
                    v-model:vendor_id="state.params.distributor"
                    v-model:useram_id="state.useram_id"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <!-- button action menu -->
        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        v-if="hasRoles([ROLE_TSO, ROLE_ADMIN_BK, ROLE_TIM_CRM])"
                        title="Upload Dokumen">
                        <AButton
                            type="primary"
                            @click="btnUpload">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_TSO, ROLE_ADMIN_BK, ROLE_TIM_CRM])"
                        title="Tambahkan">
                        <AButton
                            type="primary"
                            @click="btnForm">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.url"
                            :params="queryParams()"
                            namefile="Master-Data-Target-Salesman"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            v-if="hasRoles([ROLE_TSO, ROLE_ADMIN_BK, ROLE_TIM_CRM])"
                            title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)"
                                :disabled="record.is_action_disable">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_TSO, ROLE_ADMIN_BK, ROLE_TIM_CRM])"
                            title="Hapus">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isDelete"
                                @click="btnDelete(record)">
                                <i class="fe fe-trash" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- form -->
        <Form
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>

        <!-- upload -->
        <Upload
            v-if="visibleUploadModal"
            v-model:visible="visibleUploadModal"
            @success="fetchDataList"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterSalesman from '@/components/filter/FilterSalesman'
import FilterATSalesmanDistributorPeriode from '@/components/filter/FilterATSalesmanDistributorPeriode'
import FilterATSalesmanUserAMPeriode from '@/components/filter/FilterATSalesmanUserAMPeriode'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import Upload from './Upload'
import localStorage from 'store'
import Form from './Form'
import { includes } from 'lodash'
import moment from 'moment'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_KAM,
    ROLE_SPC,
    ROLE_SPC_GROUP,
    ROLE_CORSALES,
    ROLE_TSO,
    ROLE_TIM_CRM,
    ROLE_CRM_ATSALESMAN,
    ROLE_ASM,
    ROLE_SSM,
    ROLE_GM,
} from '@/helpers'
import {
    DateFormatMonth,
} from '@/helpers/utils'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        FilterSalesman,
        FilterATSalesmanDistributorPeriode,
        FilterATSalesmanUserAMPeriode,
        DownloadExcel,
        Form,
        Upload,
    },
    setup() {
        const errorMessage = ref()
        const user = computed(() => localStorage.get('profile'))
        const state = reactive({
            columns: [
                {
                    title: 'No',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Bulan',
                    dataIndex: 'bulan',
                    customRender: ({ text }) => DateFormatMonth(text),
                },
                {
                    title: 'ID Salesman',
                    dataIndex: 'salesman_id',
                },
                {
                    title: 'Nama Salesman',
                    dataIndex: 'salesman_name',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Distributor',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'User TSO',
                    dataIndex: 'useram_name',
                },
                {
                    title: 'Target',
                    children: [
                        {
                            title: 'Hari Kerja',
                            dataIndex: 'target_hari_kerja',
                            align: 'center',
                        },
                        {
                            title: 'Visit',
                            dataIndex: 'target_visit',
                            align: 'center',
                        },
                        {
                            title: 'Coverage',
                            dataIndex: 'target_coverage',
                            align: 'center',
                        },
                        {
                            title: 'Customer Active',
                            dataIndex: 'target_customer_active',
                            align: 'center',
                        },
                        {
                            title: 'NOO',
                            dataIndex: 'target_new_outlet',
                            align: 'center',
                        },
                        {
                            title: 'Volume',
                            dataIndex: 'target_volume',
                            align: 'center',
                        },
                    ],
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            url: '/api/atsalesman/target',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                distributor: [],
                salesman_id: [],
                useram_id: [],
                bulan: moment().format('YYYY-MM-DD'),
                page: 1,
                "per-page": 10,
            }),
            useram_id: null,
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.bulan) {
                state.params.bulan = moment(state.params.bulan).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.url, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    items.forEach((item) => {
                        item.isDelete = false
                        // disable bulan berjalan setelah 10th monthly
                        item.is_action_disable = moment(item.bulan).startOf('month').add(10, 'days').isBefore(new Date())
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnDelete = record => {
            Modal.confirm({
                title: 'Konfirmasi Hapus',
                content: 'Apakah Anda ingin hapus item tersebut?',
                onOk() {
                    record.isDelete = true

                    apiClient
                        .delete(`${state.url}/${record.id}`)
                        .then((response) => {
                            fetchDataList()
                            message.success('Berhasil dihapus')
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                        .finally(() => {
                            record.isDelete = false
                        })
                },
                onCancel() { },
            })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle upload
        const visibleUploadModal = ref(false);

        const btnUpload = () => {
            visibleUploadModal.value = true
        }

        // handle vue
        onMounted(() => {
            fetchDataList()

            // delete column
            if (hasRoles([ROLE_TSO])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['useram_name'], item.dataIndex)) {
                        return item
                    }
                })
            }

            // delete column
            // if (hasRoles([ROLE_TIM_CRM])) {
            //     state.columns = state.columns.filter(item => {
            //         if (!includes(['action'], item.dataIndex)) {
            //             return item
            //         }
            //     })
            // }

            // delete column action
            if (hasRoles([ROLE_CRM_ATSALESMAN])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['action'], item.dataIndex)) {
                        return item
                    }
                })
            }
            

            if (hasRoles([ROLE_TSO])) {
                state.useram_id = user.value.id
            }
        })

        return {
            visibleFormModal,
            visibleFormItemModal,
            btnForm, // create or update
            fetchDataList,
            state,
            handleTableChange,
            errorMessage,
            btnDelete,
            visibleUploadModal,
            btnUpload,
            queryParams,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_KAM,
            ROLE_SPC,
            ROLE_SPC_GROUP,
            ROLE_CORSALES,
            ROLE_TSO,
            ROLE_TIM_CRM,
            ROLE_CRM_ATSALESMAN,
            ROLE_ASM,
            ROLE_SSM,
            ROLE_GM,
        }
    },
})
</script>
